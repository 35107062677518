<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef" :model="searchForm">
        <a-form-model-item
            prop="outlets_city_adcode" label="投放城市" name="outlets_city_adcode">
          <a-select v-model="searchForm.outlets_city_adcode" style="width: 120px;" 
              placeholder="投放城市"
              @change="handlerSearch">
            <a-select-option v-if="!isCityManage" value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in manageCityList"
              :key="index"
              :value="item.adcode"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="name" label="官方名">
          <a-input style="width:180px" placeholder="请输入名称" v-model="searchForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="name_interior" label="内部名">
          <a-input style="width:180px" placeholder="请输入名称" v-model="searchForm.name_interior" />
        </a-form-model-item>        
        <a-form-model-item prop="event_time" label="活动日期">
          <a-range-picker
            style="width:230px"
            v-model="searchForm.event_time"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
          <a-button @click="handlerResetForm" style="margin-left: 10px">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div>
      <div class="flex justify-between">
        <span class="text-lg font-bold p-3"></span>
      </div>
      <div class="flex justify-between mr-2" style="margin-left:10px">
        <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
          <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
          <div slot="tabBarExtraContent">
            <a-button type="primary" @click="handlerAdd" html-type="submit">新建城市券投放</a-button>
          </div>
        </a-tabs>
      </div>
    </div>

    <base-table
      ref="couponTableRef"
      id="couponTableID"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #status="{text}">
        <a-tag class="mr-0" :color="text===1? 'green' : 'red' ">{{text==1? '正常' : '已停用'}}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerEdit(record)">编辑</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerUpdateStatus(record)">{{record.status === 1? '停用': '恢复'}}</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerQRCode(record)">二维码</a-button>
      </template>
    </base-table>
    
    <!-- 二维码 -->
    <a-modal
      v-model="showQRCode"
      width="300px"
      @cancel="closeQRCode"
      :footer="null">
      <div style="text-align:center;padding:10px;">
        <div id="qrcode_img"
             ref="qrcode_img"></div>
        <a-button @click="savePic" type="primary">下载</a-button>
      </div>
    </a-modal>

    <!-- 活动详情 -->
    <coupon-event-modal v-if="isShowDetail" 
      :show.sync="isShowDetail"
      :type="2"
      :modalType="modalType"
      :activeId="activeId"
      :cityList="manageCityList"
      @updated="handleComplete"
      @added="handleComplete"></coupon-event-modal>
  </div>
</template>

<script>
import pageData from "./columns"
import { getList, updateStatus } from "@/api/activity/shop-coupon-event.js"
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'

import CouponEventModal from "./components/coupon-event-modal"

import QRCode from 'qrcodejs2'
import config from '@/utils/config'
export default {
  components: { 
    CouponEventModal
  },
  data() {
    return {
      ...pageData,
      activeKey: 0,
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },
      total: 0,
      searchForm: {
        type: 2, // 线下活动
        outlets_city_adcode: undefined,
        name: "",
        name_interior: "",
        event_time: [],
        event_time_end: "",
        event_time_start: "",
        date_type: 0,
        page: 1,
        page_count: 20,
      },
      tableData: [],
      typeList: [
        { type_id: 0, type_name: "全部" },
        { type_id: 1, type_name: "未开始" },
        { type_id: 2, type_name: "进行中" },
        { type_id: 3, type_name: "已完成" },
      ],

      isCityManage: isCityManage(),
      manageCityList: [],

      activeId: "",
      isShowDetail: false,
      
      showQRCode: false,
    }
  },
  async mounted() {
    this.manageCityList = await getMarketCityList()
    // 如果是城市市场账号
    if(this.isCityManage) {
      if(this.manageCityList.length == 1){
        this.searchForm.outlets_city_adcode = this.manageCityList[0].adcode
      }
    }

    // this.initData()
    if(this.isCityManage){
      if(this.searchForm.outlets_city_adcode){
        this.handlerSearch()
      }
    }else{
      this.handlerSearch()
    }
  },
  methods: {
    // getListCoupon,
    async initData() {
      if(this.isCityManage) {
        if(!this.searchForm.outlets_city_adcode){
          this.$message.info("请选择投放城市")
          return
        }
      }

      const params = this.searchForm
      if (this.searchForm.event_time.length > 0) {
        params.event_time_start = this.searchForm.event_time[0]
        params.event_time_end = this.searchForm.event_time[1]
      }
      const { data, code } = await getList(params)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },

    // 分类切换
    handlerTypeChange(e) {
      this.activeKey = e
      this.searchForm.date_type = e
      this.searchForm.page = 1
      this.initData()
    },
    // 搜索
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    // 重置
    handlerResetForm() {
      this.activeKey = 0
      this.searchForm.name = ""
      this.searchForm.event_time_end = ""
      this.searchForm.event_time_start = ""
      this.searchForm.date_type = 0
      this.searchForm.page = 1
      this.searchForm.name_interior = ""
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    // 新增
    handlerAdd() {
      this.modalType = "add"
      this.isShowDetail = true
    },
    // 编辑
    handlerEdit(row) {
      this.activeId = row.id
      this.modalType = "edit"
      this.isShowDetail = true
    },
    handleComplete(){
      this.isShowDetail = false
      this.initData()
    },

    // 修改状态
    handlerUpdateStatus(row) {
      updateStatus({
        id: row.id,
        status: row.status === 1 ? 0 : 1,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(`${row.status === 1 ? "停用" : "恢复"}成功！`)
          this.initData()
        }
      })
    },
    //二维码
    handlerQRCode(row) {
      this.showQRCode = true
      this.$nextTick(() => {
        const d = 500
        const qrdiv = document.getElementById('qrcode_img')
        new QRCode(qrdiv, {
          width:  d,  // 二维码宽度 
          height: d, // 二维码高度
          text: config.HLL_WXACT_DOMAIN + '/#/activity/shopcouponevent?id='+row.id,
          correctLevel: QRCode.CorrectLevel.L
        })
      })
    },
    closeQRCode() {
      this.$refs.qrcode_img.innerHTML = '';
    },
    savePic() {
      let myCanvas = document.getElementById("qrcode_img").getElementsByTagName("canvas")
      let a = document.createElement("a")
      a.href = myCanvas[0].toDataURL("image/png")
      a.download = "活动二维码"
      a.click()
    }
  },
}
</script>

<style lang="less" scoped>
#qrcode_img{
  width: 180px;
  height: 180px;
  margin-bottom: 5px;
  margin: 20px auto;
}
#qrcode_img /deep/ img {
  width: 180px !important;
  height: 180px !important;
}
/deep/ .ant-form-item {
  margin-bottom: 10px;
}
</style>